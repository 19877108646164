import { useEffect, useState } from "react";
import { UserProfile } from "../../types/user.type";
import { Auth } from "aws-amplify";
import { getCognitoUrl } from "../../configs/configUtils";
import { AppContextProvider } from "../../context/AppContext";
import { Spinner } from "@amzn/awsui-components-react";

export type AuthenticatedAreaProps = {
  children: React.ReactNode;
};

const REDIRECT_URL_SESSION_KEY = "redirectUrl";

export const AuthenticatedArea: React.FC<AuthenticatedAreaProps> = ({
  children,
}) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserProfile | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authenticatedUser) => onAuthenticationSuccess(authenticatedUser))
      .catch((error) => {
        console.warn("Auth - User not Authenticated: " + error);

        // save the url opened by user before redirecting to the authentication portal
        console.log(
          "Auth - Save the url opened by user before redirecting to the authentication portal"
        );
        window.sessionStorage.setItem(
          REDIRECT_URL_SESSION_KEY,
          window.location.href
        );
        console.log("Auth - Redirect to cognito");
        window.location.replace(getCognitoUrl());
      });
  }, []);

  const onAuthenticationSuccess = (authenticatedUser: any) => {
    const payload = authenticatedUser
      .getSignInUserSession()
      .getIdToken()
      .decodePayload();
    const authenticatedUserProfile = {
      alias: payload.preferred_username,
      firstName: payload.given_name,
      lastName: payload.family_name,
      email: payload.email,
    };
    setCurrentUser(authenticatedUserProfile);
    console.log("Auth - User Authenticated:", authenticatedUserProfile);

    // redirect to original opened url by user
    console.log("Auth - Checking for redirect url:", window.sessionStorage);
    if (window.sessionStorage.getItem(REDIRECT_URL_SESSION_KEY)) {
      console.log(
        "Auth - Redirect url:",
        window.sessionStorage.getItem(REDIRECT_URL_SESSION_KEY)
      );

      const redirectUrl = window.sessionStorage.getItem(
        REDIRECT_URL_SESSION_KEY
      );
      console.log("Auth - Removing redirect url", window.sessionStorage);

      // remove the url from session storage
      window.sessionStorage.removeItem(REDIRECT_URL_SESSION_KEY);

      window.location.replace(redirectUrl!);
    } else {
      console.log(
        "Auth - Setting the IsUserAuthenticated to true",
        window.sessionStorage
      );
      setIsUserAuthenticated(true);
    }
  };

  if (isUserAuthenticated) {
    return (
      <AppContextProvider userProfile={currentUser}>
        <> {children} </>
      </AppContextProvider>
    );
  } else {
    return <Spinner size="large" />;
  }
};
